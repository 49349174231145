/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import WarningLogo from 'assets/alert-svgrepo-com.svg';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';
import { useParams } from 'react-router-dom';

const Notifications = () => {
  const [timeLeft, setTimeLeft] = useState(0);
  const { location } = useParams();
  let messageArray = [];
  const [message, setMessage] = useState([]);

  // australia standard date and  time
  const formatter = new Intl.DateTimeFormat('en-AU', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  });

  useEffect(() => {
    if (message.length > 0) {
      const timer =
        timeLeft > 0 && setInterval(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearInterval(timer);
    }
    return () => {};
  }, [timeLeft, message]);

  const wsConnect = () => {
    const socket = io('https://salt-3.ts.r.appspot.com', {
      withCredentials: true,
      extraHeaders: {
        'my-custom-header': 'abcd'
      }
    });
    // const socket = io('http://localhost:8080', {
    //   withCredentials: true,
    //   extraHeaders: {
    //     'my-custom-header': 'abcd'
    //   }
    // });
    socket.on('connect', () => {
      console.log('Connected');
    });
    socket.on('notification', (msg) => {
      const messageDescJson = JSON.parse(msg.message);
      const { type } = msg;
      // filter message by location if location is provided
      if (location) {
        if (messageDescJson.site_name !== location) {
          return;
        }
      }

      const date = new Date();
      const eventTime = formatter.format(date);
      messageDescJson.time = eventTime;

      // const constructMessage = `${messageDescJson.event} at ${messageDescJson.site_name} at ${messageDescJson.pool_name}`;
      const messageObj = {
        location: messageDescJson.site_name,
        pool: messageDescJson.pool_name,
        type,
        dateTime: eventTime,
        json: messageDescJson
      };

      if (messageArray.length > 4) {
        messageArray.shift();
      }
      messageArray.push(messageObj);
      setTimeLeft(900);
      setMessage([...messageArray]);
    });
  };

  useEffect(() => {
    setMessage([]);
    messageArray = [];
    wsConnect();
  }, []);

  return (
    <>
      <section>
        <div className="notification-container">
          <div className="grid-container">
            <div className="grid-section-1 right-border">
              <div className="grid-item ">
                <ol
                  type="1"
                  style={{
                    listStyle: 'none',
                    display: 'grid',
                    gap: '30px'
                  }}
                >
                  <>
                    {message
                      .slice(0)
                      .reverse()
                      .map((msg) => (
                        <li
                          style={{
                            marginLeft: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: `${
                              msg.type === 'attention' ? '#F0E6AD' : '#FFBBBE'
                            }`
                          }}
                          key={msg.id}
                        >
                          <div className="flex-card">
                            <div className="list-card">
                              <div
                                style={{
                                  fontSize: '24px',
                                  fontWeight: 'bold',
                                  marginBottom: '12px',
                                  textTransform: 'capitalize'
                                }}
                              >
                                {`${msg.type}`}
                              </div>
                              <div
                                style={{
                                  display: 'flex'
                                }}
                              >
                                <div
                                  style={{ fontWeight: '700', width: '70px' }}
                                >
                                  Location
                                </div>
                                {`${msg.location}`}
                              </div>
                              <div
                                style={{
                                  display: 'flex'
                                }}
                              >
                                <div
                                  style={{
                                    fontWeight: '700',
                                    width: '70px'
                                  }}
                                >
                                  Pool
                                </div>
                                {`${msg.pool}`}
                              </div>
                              <div
                                style={{
                                  display: 'flex'
                                }}
                              >
                                <div
                                  style={{ fontWeight: '700', width: '70px' }}
                                >
                                  Time
                                </div>
                                {`${msg.dateTime}`}
                              </div>
                            </div>
                            {msg?.json?.base_64_image?.image ? (
                              <div className="image-card">
                                <img
                                  src={`data:image/png;base64,${msg?.json?.base_64_image?.image}`}
                                  style={{
                                    height: '100%',
                                    width: '100%'
                                  }}
                                  alt="Bluefit.com"
                                />
                              </div>
                            ) : (
                              <div className="image-card">
                                <ReactPlaceholder
                                  type="rect"
                                  ready={false}
                                  style={{
                                    height: '100%'
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </li>
                      ))}
                    {message.length < 4 &&
                      Array.from(Array(5 - message.length)).map((i) => (
                        <ReactPlaceholder
                          key={i}
                          type="textRow"
                          rows={4}
                          ready={false}
                          showLoadingAnimation
                          style={{
                            width: '50%',
                            height: '40px',
                            margin: '10px'
                          }}
                        />
                      ))}
                  </>
                </ol>
              </div>
            </div>
            <div className="grid-section-2">
              <div
                className="grid-item"
                style={{ display: 'flex', width: '100%' }}
              >
                <div
                  style={{
                    display: `${
                      message[message.length - 1]?.type ? 'flex' : 'none'
                    }`,
                    backgroundColor: '#DEE3EB',
                    fontSize: '24px',
                    width: '60%',
                    height: '25vh',
                    flexDirection: 'column',
                    padding: '15px 0px 15px 25px'
                  }}
                >
                  <div
                    style={{
                      fontSize: '36px',
                      fontWeight: 'bold',
                      marginBottom: '12px',
                      textTransform: 'capitalize'
                    }}
                  >
                    {`${message[message.length - 1]?.type}`}
                  </div>
                  <div
                    style={{
                      display: 'flex'
                    }}
                  >
                    <div style={{ fontWeight: '700', width: '100px' }}>
                      Location
                    </div>
                    {`${message[message.length - 1]?.location}`}
                  </div>
                  <div
                    style={{
                      display: 'flex'
                    }}
                  >
                    <div style={{ fontWeight: '700', width: '100px' }}>
                      Pool
                    </div>
                    {`${message[message.length - 1]?.pool}`}
                  </div>
                  <div
                    style={{
                      display: 'flex'
                    }}
                  >
                    <div style={{ fontWeight: '700', width: '100px' }}>
                      Time
                    </div>
                    {`${message[message.length - 1]?.dateTime}`}
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    width: '40%',
                    height: '25vh'
                  }}
                >
                  {message.length === 0 ? (
                    <div className="image-card-main">
                      <ReactPlaceholder
                        type="rect"
                        ready={false}
                        style={{
                          height: '100%'
                        }}
                      />
                    </div>
                  ) : (
                    <div className="image-card-main">
                      {message[message.length - 1]?.json?.base_64_image ? (
                        <img
                          src={`data:image/png;base64,${
                            message[message.length - 1]?.json?.base_64_image
                              ?.image
                          }`}
                          style={{
                            height: '100%',
                            width: '100%'
                          }}
                          alt="Bluefit.com"
                        />
                      ) : (
                        <ReactPlaceholder
                          type="rect"
                          ready={false}
                          style={{
                            height: '100%'
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="grid-item">
                <div>
                  {timeLeft !== 0 ? (
                    <>
                      <div
                        style={{
                          width: '100%',
                          height: '55vh',
                          display: 'flex',
                          justifyContent: 'center',
                          backgroundColor:
                            message[message.length - 1]?.type === 'attention'
                              ? '#F0E6AD'
                              : '#FFBBBE'
                        }}
                      >
                        <img
                          src={WarningLogo}
                          alt="Bluefit.com"
                          width={300}
                          height={300}
                        />
                      </div>
                      <h1>Time Left: {timeLeft} seconds</h1>
                    </>
                  ) : (
                    <ReactPlaceholder
                      type="textRow"
                      ready={false}
                      showLoadingAnimation
                      style={{
                        width: '100%',
                        height: '55vh',
                        display: 'flex'
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Notifications;
