import React, { useState, useEffect } from 'react';
import GaugeChart from 'react-gauge-chart';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import axios from 'axios';

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

export const options = {
  scales: {
    y: {
      beginAtZero: true,
      display: false,
    },
    x: {
      display: false,
      beginAtZero: true,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

const Home = () => {
  const [poolData, setPoolData] = useState([]);

  const getPoolData = async () => {
    const tokens = JSON.parse(localStorage.getItem('tokens'));

    const responseData = await Promise.all(
      tokens.map((eachToken) => {
        const config = {
          method: 'get',
          url: 'https://dashboardapi.lynxight.com/api/pool_live_data',
          headers: {
            Authorization: `Token ${eachToken.token}`,
          },
        };
        return axios(config);
      })
    );

    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const safetyReportResponseData = await Promise.all(
      tokens.map((eachToken) => {
        const config = {
          method: 'get',
          url: `https://dashboardapi.lynxight.com/api/safety_event_reports?start_date=${
            yesterday.toISOString().split('T')[0]
          }T18:30:00.000Z&end_date=${
            today.toISOString().split('T')[0]
          }T18:29:59.999Z`,
          headers: {
            Authorization: `Token ${eachToken.token}`,
          },
        };
        return axios(config);
      })
    );
    if (responseData) {
      const contructedValues = responseData.map((eachPoolData, index) => {
        const eachSafetyEvent = safetyReportResponseData?.[index];
        const facilityName = tokens?.[index].siteName;
        const first = eachPoolData.data.pools.shift();
        return {
          facilityName,
          facilityData: [
            first,
            eachSafetyEvent.data.data,
            ...eachPoolData.data.pools,
          ],
        };
      });

      setPoolData(contructedValues);
    }
  };

  useEffect(() => {
    getPoolData();
    const interval = setInterval(() => {
      getPoolData();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const pool = (item, index) => {
    if (item.name === 'All pools') {
      return (
        <div key={item.id} className="total-count-main-container ">
          <div className="total-count-container">
            <div className="margin-bottom-total-container">
              <div className="swimmers-count">
                <svg
                  width="60"
                  height="60"
                  viewBox="0 0 84 85"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="swimmer-icon"
                >
                  <g filter="url(#filter0_i_367_1662)">
                    <rect
                      x="-0.000976562"
                      width="84"
                      height="85"
                      rx="5"
                      fill="#FCEBE3"
                    />
                  </g>
                  <g opacity="0.8" clipPath="url(#clip0_367_1662)">
                    <path
                      d="M56.2771 38.5534C56.2771 41.5047 53.884 43.8982 50.9323 43.8982C47.9816 43.8982 45.5889 41.5047 45.5889 38.5534C45.5889 35.602 47.982 33.21 50.9323 33.21C53.8843 33.21 56.2771 35.602 56.2771 38.5534Z"
                      fill="#ED7847"
                    />
                    <path
                      d="M43.9374 49.8979C44.2356 49.7394 47.1676 48.2607 43.8379 42.989L40.9632 36.187C40.2861 34.5863 40.2235 33.2279 40.7706 32.0343C41.7838 29.8248 44.7531 28.4582 45.8127 28.1158C47.1785 27.6862 47.9386 26.2307 47.5114 24.8625C47.0843 23.4924 45.626 22.7292 44.2552 23.156C43.6449 23.3451 38.2312 25.1497 36.0607 29.8297C34.8861 32.3592 34.9255 35.2585 36.1732 38.2123L36.4238 38.8058L20.8792 47.8132C20.8792 47.8132 34.4723 54.9443 43.9374 49.8979Z"
                      fill="#ED7847"
                    />
                    <path
                      d="M18.0491 50.293C18.0491 50.293 24.5097 55.3098 34.2579 55.3098C44.006 55.3098 44.3459 51.2299 49.7308 51.2299C55.1147 51.2299 63.0491 56.2165 63.0491 56.2165C63.0491 56.2165 59.0666 52.2407 43.6569 59.9807C28.2472 67.7207 18.0491 50.293 18.0491 50.293Z"
                      fill="#ED7847"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_i_367_1662"
                      x="-0.000976562"
                      y="0"
                      width="84"
                      height="85"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset />
                      <feGaussianBlur stdDeviation="3" />
                      <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                      />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.117647 0 0 0 0 0.0941176 0 0 0 0 0.164706 0 0 0 0.06 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_367_1662"
                      />
                    </filter>
                    <clipPath id="clip0_367_1662">
                      <rect
                        width="45"
                        height="45"
                        fill="white"
                        transform="translate(18.999 20)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <div className="total-count-margin-left">
                  <p className="swimmer-count-title">
                    {item.content.swimmersCount}
                  </p>
                  <p className="swimmer-count-sub-title">
                    &nbsp;&nbsp;&nbsp;Swimmers&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </p>
                </div>
              </div>
            </div>
            <div className="margin-bottom-total-container">
              <div className="swimmers-count">
                <svg
                  width={60}
                  height={60}
                  viewBox="0 0 84 85"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="swimmer-icon"
                >
                  <g filter="url(#filter0_i_367_1680)">
                    <rect width={84} height={85} rx={5} fill="#FCEBE3" />
                  </g>
                  <path
                    d="M56.8644 39.4948H55.5156V44.5053H56.8644V39.4948Z"
                    fill="#ED7847"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M52.6489 26.3461C50.638 23.7699 53.005 18.6415 46.934 18.9239H41.9999H37.0658C30.9947 18.6414 33.3617 23.7699 31.3508 26.3461H52.6489Z"
                    fill="#ED7847"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M52.6491 57.6541C50.6382 60.2303 53.0052 65.3587 46.9342 65.0763H42.0001H37.066C30.995 65.3587 33.3619 60.2303 31.3511 57.6541H52.6491Z"
                    fill="#ED7847"
                  />
                  <rect
                    x="29.7852"
                    y="29.7856"
                    width="24.4286"
                    height="24.4286"
                    rx="2.5"
                    stroke="#ED7847"
                    strokeWidth={3}
                  />
                  <defs>
                    <filter
                      id="filter0_i_367_1680"
                      x={0}
                      y={0}
                      width={84}
                      height={85}
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity={0} result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset />
                      <feGaussianBlur stdDeviation={3} />
                      <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2={-1}
                        k3={1}
                      />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.117647 0 0 0 0 0.0941176 0 0 0 0 0.164706 0 0 0 0.06 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_367_1680"
                      />
                    </filter>
                  </defs>
                </svg>

                <div className="total-count-margin-left">
                  <p
                    className={`swimmer-count-title ${
                      item.content.activeWatches === 0 ? 'red-color' : ''
                    }`}
                  >
                    {item.content.activeWatches}
                  </p>
                  <p className="swimmer-count-sub-title">Active Watches</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (index === 1) {
      const eachSafetyEvent = item?.[0];
      return (
        <div className="total-count-container margin-left-20 safety-container">
          <p className="safety-title">Safety Events</p>
          <div className="review-container">
            <div className="review-count">
              <p className="swimmer-count-title">
                {eachSafetyEvent?.warning +
                  eachSafetyEvent?.attention +
                  eachSafetyEvent?.overcrowding}
              </p>
              <p className="swimmer-count-sub-title">Total</p>
            </div>
            <div className="review-count">
              <p className="swimmer-count-title">{eachSafetyEvent?.warning}</p>
              <p className="swimmer-count-sub-title">Warning</p>
            </div>
            <div className="review-count">
              <p className="swimmer-count-title">
                {eachSafetyEvent?.attention}
              </p>
              <p className="swimmer-count-sub-title">Attention</p>
            </div>
            <div className="review-count">
              <p className="swimmer-count-title">
                {eachSafetyEvent?.overcrowding}
              </p>
              <p className="swimmer-count-sub-title">Overcrowding</p>
            </div>
          </div>
        </div>
      );
    }

    const riskLevel = parseFloat(
      ((item?.content?.riskLevel / 100) * 100).toFixed(),
      0.1
    );
    return (
      <div key={item.id} className="total-count-container margin-left-20">
        <div className="pool-title-container">
          <p className="poolTitle b">{item.dashboard_name}</p>
          <p
            className={`${
              !!item.status > 0 ? 'dot-green' : 'dot-red'
            } margin-l`}
          />
        </div>
        <div>
          <div className="gauage-container">
            <GaugeChart
              key={item?.id}
              id="gauge-chart2"
              nrOfLevels={1}
              textColor="black"
              arcsLength={[0.3, 0.5, 0.2]}
              colors={['#5BE12C', '#F5CD19', '#EA4228']}
              percent={riskLevel / 100}
            />
          </div>
          <div className="gauage-container status-text-center summary">
            <div className="">
              <p className="swimmer-count-title">
                {item.content.locations.length}
              </p>
              <p className="swimmer-count-sub-title">Swimmers</p>
            </div>
            <div className="">
              <p className="swimmer-count-title">
                {item.content.activeWatches.length}
              </p>
              <p className="swimmer-count-sub-title">Watches</p>
            </div>
            <div className="">
              <p className="swimmer-count-title">
                {item.content.activeWatches.length}
              </p>
              <p className="swimmer-count-sub-title">Events</p>
            </div>
            {/* <div className='risk-color-container'>
              <div className='dot-red' />
              <p>Danger</p>
            </div>
            <div className='risk-color-container'>
              <div className='dot-green' />
              <p>Normal</p>
            </div>
            <div className='risk-color-container'>
              <div className='dot-yellow' />
              <p>Warning</p>
            </div> */}
          </div>
        </div>
        {/* 
        <div className='pool-container'>
          <img id="pool-image"
            alt=''
            className="img-pool"
            src={poolBackgrounds.pool30}
          />
          <Scatter options={options}
            className="pool-layout"
            data={{
              datasets: [
                {
                  label: '',
                  data: item.content.locations,
                  backgroundColor: 'rgba(255, 99, 132, 1)',
                },
              ],
            }} />
        </div> */}
      </div>
    );
  };

  return (
    <>
      <section className="section is-main-section">
        <div className="guage-container">
          {(poolData || []).map((eachPoolSection, index) => {
            const status = eachPoolSection?.facilityData?.[0]?.status;
            return (
              <div>
                <div className="main-title">
                  <p className="pool-title">{eachPoolSection.facilityName}</p>
                  <div
                    className={`${
                      !!status > 0 ? 'system-dot-green' : 'system-dot-red'
                    }`}
                  />
                </div>
                <div key={eachPoolSection} className="each-pool-container">
                  {eachPoolSection.facilityData.map(pool, index)}
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default Home;
