export default {
  ROOT: '/',
  // optional query params
  NOTIFICATIONS: '/notifications/:location?',
  LOGIN: '/login',
  USERS: '/users',
  ADD_USER: '/users/new',
  MODIFY_USER: '/users/:id',
  PROFILE: '/profile',
  RESET_PASSWORD: '/recover-password',
  SECTION: '/section',
  SUBMENU_1: '/submenu1',
  SUBMENU_2: '/submenu2',
  SERVER_STATUS: '/server-status',
};
