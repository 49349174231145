// Server.js

import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import styles from './Server.module.scss';

const ServerStatus = () => {
  const [locations, setLocations] = useState({});

  // australia standard date and time
  const formatter = new Intl.DateTimeFormat('en-AU', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  });

  const wsConnect = () => {
    const socket = io('https://salt-3.ts.r.appspot.com', {
      withCredentials: true,
      extraHeaders: {
        'my-custom-header': 'abcd'
      }
    });
    // const socket = io('http://localhost:8080', {
    //   withCredentials: true,
    //   extraHeaders: {
    //     'my-custom-header': 'abcd'
    //   }
    // });
    socket.on('connect', () => {
      console.log('Connected to server');
    });
    socket.on('server_status', (msg) => {
      const messageDescJson = JSON.parse(msg.message);

      const date = new Date();
      const eventTime = formatter.format(date);
      messageDescJson.time = eventTime;

      const messageObj = {
        location: messageDescJson.site_name,
        pool: messageDescJson.pool_name,
        status: messageDescJson.status_str,
        dateTime: eventTime
      };

      setLocations((prevLocations) => {
        const updatedLocations = { ...prevLocations };
        if (!updatedLocations[messageObj.location]) {
          updatedLocations[messageObj.location] = [];
        }

        // Remove the previous entry for the pool in the same location
        updatedLocations[messageObj.location] = updatedLocations[
          messageObj.location
        ].filter((item) => item.pool !== messageObj.pool);

        // Add the updated entry for the pool
        updatedLocations[messageObj.location].push(messageObj);

        return updatedLocations;
      });
    });
  };

  useEffect(() => {
    setLocations({});
    wsConnect();
  }, []);

  const sortedLocations = Object.keys(locations).sort((a, b) => {
    // Sort by pool count in descending order
    const poolCountDiff = locations[b].length - locations[a].length;
    if (poolCountDiff !== 0) {
      return poolCountDiff;
    }
    // If pool count is the same, sort alphabetically by location name
    return a.localeCompare(b);
  });

  return (
    <section className={styles.serverStatus}>
      <div className={styles.container}>
        {sortedLocations.map((location) => (
          <div key={location} className={styles.locationCard}>
            <h2>{location}</h2>
            <div className={styles.poolList}>
              {locations[location].map((pool) => (
                <div
                  key={pool.pool}
                  className={`${styles.poolCard} ${
                    pool.status === 'ACTIVE' ? styles.active : styles.inactive
                  }`}
                >
                  <h3>{pool.pool}</h3>
                  <p>
                    Status: {pool.status === 'ACTIVE' ? 'Online' : 'Offline'}
                  </p>
                  <p>Time: {pool.dateTime}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServerStatus;
